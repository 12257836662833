
<template>
  <div class="privacyContainer">
    <div class="pageHeader">
        <h1>Privacy Policy for GoFindMe GPS Systems Inc.</h1>
    </div>
    <h2>
      What information do we collect?
    </h2>
    <p>
      We collect all the information you enter in our website and apps.
      We also collect and log diagnostic information which may include internet protocol (IP) addresses, browser type, internet service provider, date and time stamps, usage information,
      operating system versions, and device versions.
    </p>
    <h2>
      What we use your information for?
    </h2>
    <p>
      Any of the information we collect from you may be used for one or more of the following:
    </p>
    <ul>
      <li>
        To synchronize data between your devices.
      </li>
      <li>
        To share your data with other users of the app (only with your permission)
      </li>
      <li>
        To improve customer service.
      </li>
      <li>
        To process transactions.
      </li>
      <li>
        To send periodic emails to you about special offers.
      </li>
    </ul>
    <h2>
      Do we disclose information to outside parties?
    </h2>
    <p>
      We do not sell, trade, or otherwise transfer to outside parties any personally identifiable information. This does not include trusted third parties who assist us in operating our website,
      conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is
      appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety.
    </p>
    <p>
    <h2>
    Do we use cookies?
    </h2>
    <p>
      Yes we use cookies.
    </p>
    <h2>
      Your Consent
    </h2>
    <p>
      By using our website and/or apps you consent to our privacy policy.
    </p>
    <h2>
      Changes to our Privacy Policy
    </h2>
    <p>
      If we decide to change our privacy policy, those changes will be posted here. This policy was last modified on Dec. 11, 2021
    </p>
  </div>
</template>

<script>
export default {
  name: 'privacy',
  data () {
    return {
      msg: 'Welcome to Your Vue.js App'
    }
  }
}
</script>

<style scoped>
.privacyContainer {
  max-width: 400px;
  margin: 50px auto;
  padding: 15px 30px;
  background: #f9f7f5;
}
