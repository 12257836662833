<template>
    <div class="pageHeader">
      <h1>
        Contact us for information about GPS Tracking
      </h1>
    </div>
</template>

<script>
export default {
  name: "Tracking"
}
</script>

<style scoped>

</style>
