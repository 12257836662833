<template>
    <div class="pageHeader">
      <h1>Products</h1>
    </div>
</template>

<script>
export default {
  name: "Products"
}
</script>

<style scoped>

</style>
