import Vue from 'vue'
import VueRouter from 'vue-router';

import App from './App.vue';
import Privacy from './components/Privacy.vue';
import Tracking from './components/Tracking.vue';
import Contact from './components/Contact.vue';
import Products from './components/Products.vue';
import Home from './components/Home.vue';

Vue.use(VueRouter);

//Vue.config.productionTip = false;

const router = new VueRouter({
  routes: [
    { path: '/', name: 'home', component: Home },
    { path: '/tracking', name: 'Tracking', component: Tracking },
    { path: '/products', name: 'Products', component: Products },
    { path: '/privacy', name: 'privacy', component: Privacy },
    { path: '/contact', name: 'Contact', component: Contact }
  ]
})


new Vue({
  el: '#app',
  render: h=> h(App),
  router
});
