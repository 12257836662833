<template>
  <div id="app">
    <div class="mainHeader">
      GoFindMe GPS Systems
    </div>
    <main>
      <div class="menu">
        <div class="menu-item menuitem-text">
           <router-link to="/">Home</router-link>
        </div>
        <div class="menu-item menuitem-text">
          <router-link to="/tracking">GPS Tracking</router-link>
        </div>
        <div class="menu-item menuitem-text">
          <router-link to="/products">Products</router-link>
        </div>
        <div class="menu-item menuitem-text">
            <router-link to='/privacy'>Privacy Policy</router-link>
        </div>
        <div class="menu-item menuitem-text">
          <router-link to="/contact">Contact Us</router-link>
        </div>
      </div>
      <div class="content">
        <router-view></router-view>
      </div>
      <div class="footer">

      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {
      msg: 'Welcome to Your Vue.js App'
    }
  }
}
</script>

<style scoped>

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: blue;
    }
    body {
      background: #080808;
    }

  .mainHeader {
    text-align:center;
    width: 600px;
    margin: 0 auto;
    border-style: none;
    color: blue;
    font-size: xxx-large;
  }

    .menu {
      display: flex;
      margin-top: 10px;
    }

    .menu-item {
      margin-left: 50px;
      margin-right: 50px;
    }

    .menuitem-text {
      color: blue;
      font-size: xx-large;
    }

    .content {
      height: 1000px;
    }

    .footer {
      background-color: blue;
      height: 50px;
    }

  .pageHeader {
    text-align:center;
    width: 600px;
    margin: 0 auto;
    border-style: none;
    color: blue;
    font-size: x-large;
  }
</style>
