<template>
<div  class="pageHeader">
  <h1>Contact Us</h1>
</div>
</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped>

</style>
